import React, { useEffect } from 'react'


import { useNavigate, useLocation } from 'react-router-dom'
import { use } from 'echarts'
import { useTranslation } from 'react-i18next'

import { SaveInfo } from '../store/modules/socializeConfigStore'
import { useSelector, useDispatch } from 'react-redux'
// import { getSocializeConfigApi } from '../request/api'
function Menu ({ img, text, onClick, status }) {
  // console.log(onClick)
  return <div className='flex flex-col justify-center  ' onClick={onClick}>
    <div><img className='w-[24px] h-[24px]' src={img} alt="" /></div>
    <div className={`mt-1 ${status == true ? 'text-[#00D4BE]' : ''}`}>{text}</div>
  </div>
}
export default function Bottom () {
  const nav = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const socializeInfo = useSelector((state) => state.socializeConfigStore)
  const clickHandler = (path) => {
    console.log(path)
    nav(path)
  }

  // const getSocializeConfig = async () => {
  //   try {
  //     const res = await getSocializeConfigApi()
  //     if (res.data.code === 200) {
  //       dispatch(SaveInfo(res.data.data))
  //     }

  //   } catch (error) {
  //     console.log(error)
  //   }


  // }
  useEffect(() => {
    // console.log(loaction)
    // getSocializeConfig()
  }, [])
  return (
    <div className='flex flex-col items-center w-full pt-4'>
      <div className=' fixed bottom-4 bg-[#171920] w-[333px] h-[55px] rounded-full flex justify-around items-center'>
        {location.pathname == '/home' ?
          <Menu img={'/img/menu/home_selected.png'} text={t('home')} status={true} ></Menu>
          : <Menu img={'/img/menu/home.png'} text={t('home')} onClick={() => { nav('/home') }}></Menu>}
        {location.pathname == '/record' ?
          <Menu img={'/img/menu/record_selected.png'} text={t('recording')} status={true} ></Menu>
          : <Menu img={'/img/menu/record.png'} text={t('recording')} onClick={() => { nav('/record') }}></Menu>}
        {location.pathname == '/myAssets' ?
          <Menu img={'/img/menu/asset_selected.png'} text={t('asset')} status={true} ></Menu>
          : <Menu img={'/img/menu/asset.png'} text={t('asset')} onClick={() => { nav('/myAssets') }}></Menu>}
        {location.pathname == '/my' ?
          <Menu img={'/img/menu/my_selected.png'} text={t('itSMine')} status={true} ></Menu>
          : <Menu img={'/img/menu/my.png'} text={t('itSMine')} onClick={() => { nav('/my') }}></Menu>}
      </div>
    </div>
  )
}
